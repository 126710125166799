import { DownOutlined } from '@ant-design/icons'
import { Button, Dropdown, Popconfirm, Space, Switch, Typography } from 'antd'
import { MenuProps } from 'antd/lib/menu'
import { Trans, useTranslation } from 'react-i18next'
import { TaskMode } from 'services/Tasks.slice'
import { Status } from 'types/Status'
import { ScheduledTask } from 'types/Tasks'
import { Userpilot } from 'userpilot'
import { TaskFields, TaskInternals } from 'utils/permissions'
import { useTaskEditor } from '../useTaskEditor'

const { Text } = Typography

enum SaveAction {
  SAVE_AND_NEW = 'save_and_new',
  SAVE_AND_CLOSE = 'save_and_close',
  SAVE = 'save',
}

type SaveActionsProps = {
  isConfirmOpen: boolean
  setIsConfirmOpen: (isConfirmOpen: boolean) => void
  closeModal: () => void
  updateScheduledTask: (scheduledTask?: Partial<ScheduledTask>) => void
  resetFields: () => void
  setCheckItemsCount: (checkItemCount: number) => void
  setCheckedItems: (checkedItems: number) => void
  isCustomFieldTabNotDirty: () => boolean
  goToCustomFieldTab: () => void
  checkDirty: () => void
  isTemplate: boolean
  isDocked?: boolean
  taskMode: string
  setTaskMode: React.Dispatch<React.SetStateAction<TaskMode>>
  isRoutineInstance: boolean
  isRoutinePopconfirmOpen: boolean
  submitAndModifyAssociatedTask: () => void
  submitAndDoNotModifyOtherTask: () => void
  setSubItems: (items: any[]) => void
  status: Status
  dispatch: React.Dispatch<any>
  saveAction: {
    background: string
    color: string
    text?: string
    actionText: string
  }
  submit: () => void
}

const SaveActions = ({
  isConfirmOpen,
  setIsConfirmOpen,
  closeModal,
  updateScheduledTask,
  resetFields,
  setCheckItemsCount,
  setCheckedItems,
  isCustomFieldTabNotDirty,
  goToCustomFieldTab,
  checkDirty,
  isTemplate,
  isDocked,
  taskMode,
  setTaskMode,
  isRoutineInstance,
  isRoutinePopconfirmOpen,
  submitAndModifyAssociatedTask,
  submitAndDoNotModifyOtherTask,
  setSubItems,
  status,
  dispatch,
  saveAction,
  submit,
}: SaveActionsProps) => {
  const { t } = useTranslation()
  const { setInitialValues, hasUpdatePermissionOnTask: hasFieldPermission } =
    useTaskEditor()

  const items: MenuProps['items'] = [
    ...(!isTemplate && !isDocked
      ? [
          {
            label: t('actions.save-and-new'),
            key: 'save_and_new',
          },
        ]
      : []),
    {
      label: t('actions.save-and-close'),
      key: 'save_and_close',
    },
    {
      label: t('actions.save'),
      key: 'save',
    },
  ]

  return (
    <Space
      wrap
      style={{
        flex: '1 1 auto',
        justifyContent: 'right',
      }}
    >
      <Popconfirm
        title={t('new-task-form.discard-prompt.title')}
        description={t('new-task-form.discard-prompt.description')}
        open={isConfirmOpen}
        onConfirm={() => {
          setIsConfirmOpen(false)
          closeModal()
          updateScheduledTask(undefined)
          resetFields()
          setCheckItemsCount(0)
          setCheckedItems(0)
          setInitialValues(undefined)
        }}
        onCancel={() => {
          if (!isCustomFieldTabNotDirty()) {
            goToCustomFieldTab()
          }
          setIsConfirmOpen(false)
        }}
        icon={null}
        okText={t('new-task-form.discard-prompt.ok-text')}
        cancelText={t('new-task-form.discard-prompt.cancel-text')}
      >
        <Button onClick={checkDirty}>{t('actions.close')}</Button>
      </Popconfirm>
      {!isTemplate && (
        <Switch
          checked={taskMode === TaskMode.ACTIVE ? true : false}
          checkedChildren={
            <Space align="center" style={{ height: 29 }}>
              <Text style={{ color: '#FFF' }}>{t('new-task-form.active')}</Text>
            </Space>
          }
          unCheckedChildren={
            <Space align="center">
              <Text style={{ color: 'var(--text-color)' }}>
                {t('new-task-form.draft')}
              </Text>
            </Space>
          }
          loading={status === Status.LOADING && taskMode === TaskMode.DRAFT}
          disabled={
            status === Status.LOADING || !hasFieldPermission(TaskFields.MODE)
          }
          style={{
            backgroundColor:
              taskMode === TaskMode.DRAFT
                ? 'var(--not-started-color)'
                : 'var(--completed-color)',
            height: 30,
          }}
          onClick={(e) => {
            if (e) {
              setTaskMode(TaskMode.ACTIVE)
            } else {
              setTaskMode(TaskMode.DRAFT)
            }
          }}
        />
      )}
      <Popconfirm
        title={
          isRoutineInstance
            ? t('routines.modify-definition-title', {
                ns: 'validation',
              })
            : t('routines.modify-instance-title', {
                ns: 'validation',
              })
        }
        description={
          isRoutineInstance
            ? t('routines.modify-routine-definition', {
                ns: 'validation',
              })
            : t('routines.modify-today-instance', {
                ns: 'validation',
              })
        }
        open={isRoutinePopconfirmOpen}
        onConfirm={submitAndModifyAssociatedTask}
        onCancel={submitAndDoNotModifyOtherTask}
        okText={t('actions.yes')}
        cancelText={t('actions.no')}
      />
      <Dropdown
        menu={{
          items,
          onClick: (event) => {
            Userpilot.track('Saved a task')
            submit()

            if (event.key === SaveAction.SAVE) {
              return dispatch({ type: SaveAction.SAVE })
            }

            if (event.key === SaveAction.SAVE_AND_CLOSE) {
              return dispatch({ type: SaveAction.SAVE_AND_CLOSE })
            }

            dispatch({ type: SaveAction.SAVE_AND_NEW })
            setSubItems([])
          },
        }}
        trigger={['hover']}
        disabled={!hasFieldPermission(TaskInternals.SUBMIT_TASK)}
      >
        <Button
          style={{
            backgroundColor: saveAction.background,
            color: saveAction.color,
          }}
          htmlType="submit"
          disabled={
            !hasFieldPermission(TaskInternals.SUBMIT_TASK) ||
            status === Status.LOADING
          }
          onClick={() => {
            Userpilot.track('Saved a task')
          }}
        >
          <Space>
            <Trans>{saveAction.actionText}</Trans>
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
    </Space>
  )
}

export default SaveActions
