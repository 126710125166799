import { useState, useEffect } from 'react'
import Row from 'components/Row'
import dayjs, { Dayjs } from 'dayjs'
import Dropdown from 'components/Dropdown'
import { CalendarOutlined } from '@ant-design/icons'
import { Button, Switch, DatePicker } from 'antd'
import { ScheduledTask } from 'types/Tasks'
import { FlexibleTime } from 'types/FlexibleTime'
import { recurRuleFromString } from 'utils/recurRule'
import { useTranslation } from 'react-i18next'
import type { RangePickerProps } from 'antd/es/date-picker'
import useStore from 'hooks/useStore'
import SelectMultipleContacts from 'components/SelectMultipleContacts'
import { isValidEmail } from 'utils/taskUtils'

type SchedulingDropdownProps = {
  value?: Partial<ScheduledTask> | null
  onChange?: (value?: Partial<ScheduledTask> | null) => void
  title?: string
  outlined?: boolean
  iconClassName?: string
  iconStyles?: React.CSSProperties
  setRoutineIsOpen?: (newValue: boolean) => void
  flexibleTime?: FlexibleTime
  setFlexibleTime: (f: FlexibleTime | undefined) => void
  showDateAfterIcon?: boolean
  disabled?: boolean
}

const SchedulingDrowdown = ({
  title,
  outlined,
  iconClassName,
  iconStyles,
  setRoutineIsOpen = () => {},
  onChange: onSave,
  value: scheduledTask,
  flexibleTime,
  setFlexibleTime,
  showDateAfterIcon,
  disabled,
}: SchedulingDropdownProps) => {
  const { t } = useTranslation()
  const rRule = recurRuleFromString(scheduledTask?.recurRule)
  const showDate =
    showDateAfterIcon && scheduledTask?.startDate && !rRule.isRecurring()
  const [isOpen, setOpen] = useState(false)
  const [isAllDay, setAllDay] = useState(false)
  const [startTime, setStartTime] = useState(
    scheduledTask?.startDate
      ? dayjs(scheduledTask?.startDate)
      : dayjs().minute(0).second(0).millisecond(0),
  )
  const [endTime, setEndTime] = useState(
    scheduledTask?.endDate
      ? dayjs(scheduledTask?.endDate)
      : startTime?.add(1, 'hour'),
  )
  const { localTimeZoneId, timeFormatOption, dateFormatOption } = useStore(
    (state) => state,
  )
  const [currentAttendees, setCurrentAttendees] = useState<string[]>([])
  const [isInvalid, setIsInvalid] = useState<boolean>(false)

  const disabledDate: RangePickerProps['disabledDate'] = (current) => {
    return current && current < dayjs().add(-1, 'day').endOf('day')
  }

  const handleChangeAttendees = (value: string[]) => {
    const isInvalid = !!value.filter((attendee) => !isValidEmail(attendee))
      .length
    setIsInvalid(isInvalid)
    setCurrentAttendees(value)
  }

  useEffect(() => {
    if (scheduledTask) {
      setAllDay(scheduledTask.allDay || false)
      const listAttendees = (scheduledTask.scheduledAttendees || []).map(
        (attendee) => attendee.email!,
      )
      const listPendings = (scheduledTask.pendingAttendees || []).map(
        (attendee) => attendee.email!,
      )
      setCurrentAttendees([...listAttendees, ...listPendings])
    }

    if (scheduledTask?.startDate) {
      setStartTime(dayjs(scheduledTask?.startDate))
      setEndTime(dayjs(scheduledTask?.endDate))
    }
  }, [scheduledTask])

  const handleRemove = () => {
    setStartTime(dayjs().minute(0).second(0).millisecond(0))
    setEndTime(dayjs().minute(0).second(0).millisecond(0).add(1, 'hour'))
    onSave?.(null)
    setOpen(false)
  }

  const handleSave = () => {
    if (isInvalid) {
      return
    }

    const rRule = {
      id: scheduledTask?.id || undefined,
      startDate: startTime.toISOString(),
      endDate: endTime.toISOString(),
      recurRule: '',
      rDate: '',
      exDate: '',
      // For consistency keep the original timezone
      timezoneId: scheduledTask?.timezoneId || localTimeZoneId,
      allDay: isAllDay,
      scheduledAttendees: currentAttendees.map((value) => ({ email: value })),
      pendingAttendees: undefined,
    } as ScheduledTask
    onSave?.(rRule)
    setOpen(false)
  }

  const handleOpenRoutine = () => {
    setFlexibleTime(FlexibleTime.SCHEDULED)
    setRoutineIsOpen(true)
    setOpen(false)
  }

  const getRangeFormat = () => {
    const dateFormat = dateFormatOption === 'month_first' ? 'L' : 'DD/MM/YYYY'
    const timeFormat = timeFormatOption === 24 ? 'HH:mm' : 'hh:mm A'
    return `${dateFormat} @ ${timeFormat}`
  }

  return (
    <Dropdown
      outlined={outlined}
      title={title}
      isOpen={isOpen}
      icon={<CalendarOutlined className={iconClassName} style={iconStyles} />}
      onChange={(nextValue) => {
        setOpen(nextValue)
      }}
      label={showDate ? isAllDay ? dayjs.utc(scheduledTask?.startDate).format('ddd DD') : dayjs(scheduledTask?.startDate).format('ddd DD') : ''}
      isReadonly={disabled}
    >
      <div style={{ width: '450px' }}>
        <h3 style={{ margin: '16px' }}>{title}</h3>
        <Row style={{ padding: '0px 16px 8px 16px' }}>
          {isAllDay ? (
            <DatePicker.RangePicker
              size="small"
              showTime={false}
              disabledDate={disabledDate}
              defaultValue={[startTime, endTime.subtract(1, 'day').startOf('day')]}
              format={dateFormatOption === 'month_first' ? 'L' : 'DD/MM/YYYY'}
              style={{
                flexGrow: 0,
                transition: 'all 0.4s ease',
              }}
              onChange={(newDates) => {
                const start = newDates?.[0]
                const end = newDates?.[1]
                if (!start || !end) {
                  return
                }
                setStartTime(dayjs(start.format('YYYY-MM-DD') + 'T00:00:00Z'))
                setEndTime(dayjs(end.add(1, 'day').format('YYYY-MM-DD') + 'T00:00:00Z'))
              }}
            />
          ) : (
            <DatePicker.RangePicker
              size="small"
              showTime={{
                hideDisabledOptions: true,
                defaultValue: [
                  startTime.minute(0).second(0).millisecond(0),
                  startTime.add(1, 'hour'),
                ],
              }}
              disabledDate={disabledDate}
              defaultValue={[startTime, endTime]}
              format={getRangeFormat()}
              style={{
                flexGrow: 1,
                transition: 'all 0.4s ease',
              }}
              onChange={(newDates) => {
                setStartTime(newDates?.[0] as Dayjs)
                setEndTime(newDates?.[1] as Dayjs)
              }}
            />
          )}
        </Row>
        <Row style={{ padding: '0px 16px 8px 16px' }}>
          <span>
            All Day
            <Switch
              size="small"
              checked={isAllDay}
              style={{ marginLeft: '8px' }}
              onChange={() => {
                setStartTime(dayjs(startTime.format('YYYY-MM-DD') + 'T00:00:00Z'))
                setEndTime(dayjs(endTime.add(1, 'day').format('YYYY-MM-DD') + 'T00:00:00Z'))
                setAllDay((prevState) => !prevState)
              }}
            />
          </span>
        </Row>
        <Row style={{ padding: '0px 16px 8px 16px' }}>
          <span>{t('routines.attendees')}:</span>
          <SelectMultipleContacts
            defaultValue={currentAttendees}
            onChange={handleChangeAttendees}
            isInvalid={isInvalid}
          />
        </Row>
        <Row style={{ padding: '0px 16px' }}>
          <span>
            Recurring
            <Switch
              size="small"
              style={{ marginLeft: '8px' }}
              onClick={handleOpenRoutine}
            />
          </span>
        </Row>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            padding: '0px 16px 16px 8px',
          }}
        >
          <Button
            type="default"
            onClick={handleRemove}
            style={{ marginRight: 8 }}
          >
            {t('actions.remove')}
          </Button>
          <Button type="primary" onClick={handleSave}>
            {t('actions.save')}
          </Button>
        </div>
      </div>
    </Dropdown>
  )
}

export default SchedulingDrowdown
