import { StateCreator } from 'zustand'
import { ApiState } from './Auth.slice'
import apiBase from 'utils/axiosBase'
import {
  UserSettings,
  UserConfig,
  TimeFormat,
  TimeFormatOption,
  DateFormat,
  DateFormatOption,
  UserConfigPost,
} from 'types/Settings'
import { TimeZone } from 'types/TimeZone'
import { getTimeZone, formatTo24HourFormat } from 'utils/IntlUtlis'
import { getInitials, arraysAreDifferent } from 'utils/settings'

export enum SaveAction {
  SAVE_AND_NEW = 'save_and_new',
  SAVE_AND_CLOSE = 'save_and_close',
  SAVE = 'save',
}

const DEFAULT_TIMEZONE_ID = 35 // America/New_York

const defaultSelectedLevels = [...new Array(9)].map((_, index) => index + 1)

export interface SettingsState extends ApiState {
  updateUser: (settings: Partial<UserSettings>) => Promise<void>
  getUserConfig: () => Promise<UserConfig>
  updateUserConfig: (
    newConfiguration: Partial<UserConfigPost>,
  ) => Promise<UserConfig>
  localTimeZoneId: number
  localTimeZoneCode: string | undefined
  dateFormat: DateFormat
  dateFormatOption: DateFormatOption
  timeFormat: TimeFormat
  timeFormatOption: TimeFormatOption
  userId?: string
  saveAction?: SaveAction
  setSaveAction: (action: SaveAction) => void
  getTimeZoneList: () => Promise<TimeZone[]>
  setInitialTimeZone: (code?: string) => Promise<TimeZone | undefined>
  getWPLoginUrl: () => Promise<string>
  fullAvatar?: string
  thumbnailAvatar?: string
  avatarInitials?: string
  getAvatar: (userId?: string) => Promise<void>
  updateAvatar: (body: FormData) => Promise<void>
  deleteAvatar: (userId?: string) => Promise<void>
  clearSettingsSlice: () => void
  favoriteGroup?: number
  selectedLevels: number[]
}

export const createSettingsSlice: StateCreator<
  SettingsState,
  [['zustand/immer', never]]
> = (set, get) => ({
  getUserConfig: async () => {
    const response = await get().api!('GET', `/user/config/`)
    // @ts-ignore
    const { updateRoutineTimesState } = get()
    const config: UserConfig = response.data
    updateRoutineTimesState(config.routineTimes)
    set((draft) => {
      draft.timeFormatOption = config.timeFormatOption
      draft.timeFormat = config.timeFormatOption === 12 ? 'hh:mm a' : 'HH:mm'
      draft.dateFormatOption = config.dateFormatOption
      draft.dateFormat =
        config.dateFormatOption === 'day_first' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'
      draft.userId = config.user
      draft.favoriteGroup = config.group
        ? parseInt(config.group.id as unknown as string)
        : undefined
      // The ts-ignore is needed here since the user state value exists on the Auth slice
      // and TS can't verify that it exists
      draft.avatarInitials = getInitials(
        // @ts-ignore
        get().user.data.firstName,
        // @ts-ignore
        get().user.data.lastName,
      )
      const newLevels =
        config.preferences?.selectedLevels || defaultSelectedLevels
      if (arraysAreDifferent(draft.selectedLevels, newLevels)) {
        draft.selectedLevels = newLevels
      }
    })
    return config
  },
  updateUserConfig: async (newConfig) => {
    const { routineTimes } = newConfig
    if (routineTimes) {
      newConfig.routineTimes = formatTo24HourFormat(routineTimes)
    }

    const response = await get().api!('PATCH', `/user/config/`, newConfig)
    const config: UserConfig = response.data
    set((draft) => {
      draft.timeFormatOption = config.timeFormatOption
      draft.timeFormat = config.timeFormatOption === 12 ? 'hh:mm a' : 'HH:mm'
      draft.dateFormatOption = config.dateFormatOption
      draft.dateFormat =
        config.dateFormatOption === 'day_first' ? 'DD/MM/YYYY' : 'MM/DD/YYYY'
      draft.userId = config.user
      draft.favoriteGroup = config.group
        ? parseInt(config.group.id as unknown as string)
        : undefined
      const newLevels = config.preferences.selectedLevels
      if (newLevels) {
        if (arraysAreDifferent(draft.selectedLevels, newLevels)) {
          draft.selectedLevels = newLevels
        }
      }
    })
    // @ts-ignore
    const { updateRoutineTimesState } = get()
    updateRoutineTimesState(config.routineTimes)
    return config
  },
  updateUser: async (settings: Partial<UserSettings>) => {
    // @ts-ignore
    const { getToken, updateUserState } = get()
    const token = await getToken()
    const body = {
      first_name: settings.firstName,
      last_name: settings.lastName,
      email: settings.email,
    }
    const headers = {
      Authorization: `Bearer ${token.access}`,
    }
    const response = await apiBase.patch('/auth/user/', body, {
      headers,
    })
    if (response.status === 200) {
      const user = {
        email: response.data.email,
        firstName: response.data.first_name,
        id: response.data.id,
        lastName: response.data.last_name,
        typeId: response.data.type_id,
        otpEnabled: response.data.otp_enabled,
      }
      updateUserState(user)
    }
    // @ts-ignore
    const { updateRoutineTimesState } = get()
    updateRoutineTimesState(response.data.routineTimes)
    return response.data
  },
  localTimeZoneId: DEFAULT_TIMEZONE_ID,
  localTimeZoneCode: undefined,
  // Default values if not provided or ​​until the values ​​are loaded in settings
  timeFormatOption: 24,
  timeFormat: 'HH:mm',
  dateFormat: 'MM/DD/YYYY',
  dateFormatOption: 'month_first',
  userId: undefined,
  favoriteGroup: undefined,
  saveAction: SaveAction.SAVE_AND_CLOSE,
  selectedLevels: defaultSelectedLevels,
  setSaveAction: (action) => {},
  getTimeZoneList: async () => {
    const response = await get().api!('GET', `/user/timezone/`)
    return response.data
  },
  setInitialTimeZone: async (code) => {
    const timezone = getTimeZone()
    if (timezone.value) {
      if (get().localTimeZoneCode === timezone.value) {
        return
      }
      const response = await get().api!(
        'GET',
        `/user/timezone/?code=${escape(timezone.value)}`,
      )
      const objTimeZone: TimeZone = response.data?.[0]
      if (objTimeZone?.id) {
        set((draft) => {
          draft.localTimeZoneId = objTimeZone?.id
          draft.localTimeZoneCode = timezone.value
        })
      }
      return objTimeZone
    }
  },
  getWPLoginUrl: async () => {
    // @ts-ignore
    const { getToken } = get()
    const token = await getToken()
    const headers = {
      Authorization: `Bearer ${token.access}`,
    }
    const response = await apiBase.get('/auth/login/wp', { headers })
    if (response?.data?.detail) {
      return response.data.detail.url
    }
    return ''
  },
  fullAvatar: undefined,
  thumbnailAvatar: undefined,
  getAvatar: async (userId) => {
    const id = userId ? userId : get().userId
    try {
      const response = await get().api!('GET', `/user/avatar/${id}`)
      if (response.status === 200) {
        const { originalUrl, thumbnailUrl } = response.data
        if (originalUrl && thumbnailUrl) {
          set((draft) => {
            draft.fullAvatar = originalUrl
            draft.thumbnailAvatar = thumbnailUrl
          })
        }
      } else {
        set((draft) => {
          draft.fullAvatar = undefined
          draft.thumbnailAvatar = undefined
        })
      }
    } catch (error) {
      set((draft) => {
        draft.fullAvatar = undefined
        draft.thumbnailAvatar = undefined
      })
    }
  },
  updateAvatar: async (body) => {
    try {
      const response = await get().api!('POST', '/user/avatar/', body, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
      if (response.status === 201) {
        const { originalUrl, thumbnailUrl } = response.data
        set((draft) => {
          draft.fullAvatar = originalUrl
          draft.thumbnailAvatar = thumbnailUrl
        })
      }
    } catch (error) {
      console.error('Error uploading avatar', error)
      throw error
    }
  },
  deleteAvatar: async (userId) => {
    const id = userId || get().userId
    try {
      const response = await get().api!('DELETE', `/user/avatar/${id}`)
      if (response.status === 204) {
        set((draft) => {
          draft.fullAvatar = ''
          draft.thumbnailAvatar = ''
        })
      }
    } catch (error) {
      console.error('Error deleting avatar', error)
      throw error
    }
  },
  clearSettingsSlice: () => {
    set((draft) => {
      draft.fullAvatar = ''
      draft.thumbnailAvatar = ''
      draft.dateFormat = 'MM/DD/YYYY'
      draft.dateFormatOption = 'month_first'
      draft.timeFormat = 'HH:mm'
      draft.timeFormatOption = 24
      draft.userId = ''
      draft.avatarInitials = ''
      draft.saveAction = SaveAction.SAVE
      draft.selectedLevels = defaultSelectedLevels
    })
  },
})
