import React from 'react'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'

type CloseButtonProps = {
  onClick: () => void
  visible: boolean
}

const CloseButton = ({ onClick, visible }: CloseButtonProps) => {
  if (visible) {
    return (
      <IconButton
        aria-label="close"
        onClick={onClick}
        sx={{ position: 'absolute', top: 10, right: 10 }}
      >
        <CloseIcon fontSize="large" sx={{ color: '#000' }} />
      </IconButton>
    )
  }
  return null
}

export default CloseButton
