import dayjs, { Dayjs } from 'dayjs'
import { ScheduledTask, TaskItem, TaskPriorityGroup } from 'types/Tasks'
import {
  buildFilterQuery,
  getStartDateEndDateForAPICall,
} from 'utils/taskUtils'
import type { StateCreator } from 'zustand'
import { ApiState } from './Auth.slice'
import { TaskLists, TaskMode, TaskType } from './Tasks.slice'
import { getTimeZone } from 'utils/IntlUtlis'

type CalendarFilter = {
  groups?: number[]
  delegatedToMe?: boolean
  day?: Dayjs
  viewType: 'day' | 'month'
  routinesOnly?: '1'
}

export interface CalendarState extends ApiState {
  getScheduledTasks: (params: CalendarFilter) => Promise<TaskItem[]>
  workWeekName: string
  workWeekDays: number[]
  setWorkWeekName: (name: string) => void
  workWeekHours: { start: number; end: number }
  editScheduledTask: (
    id: number,
    data: Partial<ScheduledTask>,
    userId: string,
  ) => Promise<TaskItem>
  deleteScheduledTask: (id: number) => Promise<number>
  updatePriorityGroupAndTaskPosition: (
    task: TaskItem,
    userId: string,
  ) => Promise<void>
}

export const createCalendarSlice: StateCreator<CalendarState> = (set, get) => ({
  getScheduledTasks: async ({
    groups,
    delegatedToMe,
    day,
    viewType,
    routinesOnly,
  }) => {
    const filters = new Map<string, string | string[] | number[]>()
    if (groups) {
      if (groups.length === 0) {
        // Send a value in group parameter when used deselect all groups
        filters.set('group', [0])
      } else {
        filters.set('group', groups)
      }
    }
    if (delegatedToMe) {
      filters.set('type', 'delegated_to_me')
    }
    if (routinesOnly) {
      filters.set('routines_only', routinesOnly)
    }
    if (day) {
      const { startDate, endDate } = getStartDateEndDateForAPICall(
        day,
        viewType,
      )
      filters.set('date_type', 'start_end_date')
      filters.set('start_date', startDate)
      filters.set('end_date', endDate)
    }

    const filterQuery = buildFilterQuery(filters)
    const response = await get().api!('GET', `task/schedule?${filterQuery}`)
    if (response.status === 200) {
      const result: TaskItem[] = response?.data
      return result
    }
    return []
  },
  workWeekName: 'Work Week',
  setWorkWeekName: (name: string) => set({ workWeekName: name }),
  workWeekDays: [0, 6],
  workWeekHours: { start: 9, end: 19 },
  editScheduledTask: async (id, data, userId) => {
    const response = await get().api!('PATCH', `task/schedule/${id}`, data)
    if (response.status === 200) {
      // Update my-today with new values
      // @ts-ignore
      const list: TaskLists = get().tasks?.list || {}
      const editedTask: TaskItem = response.data
      get().updatePriorityGroupAndTaskPosition(editedTask, userId)
      for (const value of Object.values(list)) {
        const currentTask = value.find((task) => task.id === editedTask.id)
        if (currentTask) {
          // Copy current roles
          const taskToUpdate = { ...editedTask, roles: currentTask.roles }
          // @ts-ignore
          get().updateTaskState([taskToUpdate], false)
          return taskToUpdate
        }
      }
      return editedTask
    }
    return response.data
  },
  deleteScheduledTask: async (id) => {
    const response = await get().api!('DELETE', `task/schedule/${id}`)
    const status: number = response?.status
    return status
  },
  updatePriorityGroupAndTaskPosition: async (
    task: TaskItem,
    userId: string,
  ) => {
    if (!task.scheduledTask) {
      return
    }
    if (task.scheduledTask.recurRule) {
      return
    }
    if (task.mode !== TaskMode.ACTIVE) {
      return
    }
    if (task.completionAt) {
      return
    }
    if (task.priorityGroup?.id === TaskPriorityGroup.RoutineDefinition) {
      return
    }
    if (task.taskAssignees?.[0]?.user !== userId) {
      return
    }
    const startDate = dayjs(task.scheduledTask.startDate)
    const priorityGroup = dayjs().isSame(startDate, 'day')
      ? TaskPriorityGroup.Now
      : TaskPriorityGroup.Scheduled

    if (task.priorityGroup?.id === priorityGroup) {
      return
    }
    const updates: TaskType = {
      priorityGroup,
      taskPosition: priorityGroup === TaskPriorityGroup.Now ? 'top' : undefined,
    }
    try {
      updates!.userTimezone = getTimeZone().value
      await get().api!('PATCH', `/task/${task.id}`, updates)
    } catch (error) {
      console.error('An error occurred while updating the task: ', error)
      throw error
    }
  },
})
