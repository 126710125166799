export const getInitials = (firstName: string, lastName: string): string => {
  return `${firstName.slice(0, 1).toUpperCase()}${lastName
    .slice(0, 1)
    .toUpperCase()}`
}

export const arraysAreDifferent = (arr1: number[], arr2: number[]) => {
  if (arr1.length !== arr2.length) {
    return true
  }
  for (let i = 0; i < arr1.length; i++) {
    if (arr1[i] !== arr2[i]) {
      return true
    }
  }
  return false
}
