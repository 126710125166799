import { ChangeSet } from '@devexpress/dx-react-grid'
import { TaskMode, UploadedFile } from 'services/Tasks.slice'
import {
  CustomField,
  CustomFieldValue,
  CustomFieldValueDataFormat,
} from './CustomFields'
import { RoleMappingBase } from './Templates'
import { TaskRole } from './Permission'
import dayjs from 'dayjs'
export type TaskDetails = {
  description: string
  estimatedHour?: string
  flag?: TaskCategory
}

export enum DelegationType {
  INDIVIDUAL_PARENT = 'IP',
  INDIVIDUAL_CHILD = 'IC',
  SHARED = 'SH',
  SINGLE = 'SI',
}

export type TaskCategory = {
  id: number
  title: string
}

export type UUIDTitle = {
  id: string
  title: string
}

export type TaskItem = {
  id: string
  user: TaskUser
  title: string
  mode: string
  level?: TaskCategory
  completionAt?: string | null
  priority?: number
  priorityGroup?: TaskCategory
  owner?: string
  isTracking?: boolean
  createdAt?: string
  dueAt?: string
  group: TaskGroup
  taskDetails?: TaskDetails
  status?: TaskStatus
  taskAssignees?: TaskAssignees[]
  taskAttachments?: UploadedFile[]
  pendingAssignees?: PendingAssignee[]
  scheduledTask?: ScheduledTask | null
  taskSortingCode?: string
  originalTaskId?: string
  children?: TaskItem[]
  routineTask?: string
  parent?: UUIDTitle
  customFields?: CustomField[]
  customData?: CustomFieldValue[]
  isCustomValueRequired?: boolean
  curriculumTaskInstance?: { id: number; status: string }
  taskAttachmentsCount?: number
  taskRole?: RoleMappingForTaskNode | null
  assignedTaskRoles?: string[]
  roles?: number[]
  inputType?: string
  manager: TaskUser
  delegationType: DelegationType
}

export type TaskItemWithCuriculumId = TaskItem & { curriculumTaskId?: number }

export type TaskPriorityGroupUser = TaskPriorityGroupGlobal & {
  parent: TaskCategory
}

export type TaskPriorityGroupGlobal = {
  id: number
  title: string
  days: number
  order: number
}

export type TaskStatus = {
  id: number
  title: string
}

export enum TaskStatusId {
  NOT_STARTED = 1,
  ACTIVE = 2,
  PAUSED = 3,
  DONE = 4,
}

export enum TaskPriority {
  URGENT = 1,
  NORMAL = 3,
}

export enum TaskPriorityGroup {
  Now = 0,
  Next = 1,
  Later = 2,
  Future = 3,
  Scheduled = 4,
  Routine = 5,
  RoutineDefinition = 6,
}

export enum Frequency {
  DAY = 'Daily',
  WEEK = 'Weekly',
  MONTH = 'Monthly',
  YEAR = 'Yearly',
}

export const FuzzyTimeOptions: { [key: number]: string } = {
  0: '',
  1: 'Morning',
  2: 'Afternoon',
  3: 'Evening',
}

export type TaskGroupName = 'personal' | 'family' | 'business' | 'professional'

export type TaskLevelUser = TaskLevel & {
  parent: TaskCategory
}

export type TaskLevel = {
  id: number
  title: string
}

export type TaskAssignees = {
  id?: number
  assigneeName?: string
  user: string
  createdAt: string
  email?: string
  avatar?: string
  role?: TaskRole[]
}

export type PendingAssignee = {
  email: string
  status: string
  name?: string
  role?: TaskRole[]
  image?: string
  createdAt?: string
}

export type ScheduledAttendee = {
  id: number
  user: TaskUser
  email: string
  attendeeType: string
  status: string
  joinedAt?: string
}

export type ScheduledTask = {
  task: string
  id: number
  startDate: Date | string
  endDate?: Date | string
  duration?: number
  allDay?: boolean
  rDate?: string
  exDate?: string
  recurRule?: string
  recurRuleLastDate?: Date | string
  timezoneId?: number
  isActive?: 0 | 1
  scheduledAppointment?: Array<any>
  flexibleTime?: number
  initialDate?: Date | string
  scheduledAttendees: Partial<ScheduledAttendee>[]
  pendingAttendees?: Partial<PendingAssignee>[]
  readonlyExDate?: string
  futureInstances?: string
}

export type TaskSchedule = {
  id: number
  startTime: string
  endTime: string
  bufferTime: string
  isRecurring: number
  isActive: number
  appointmentDate: string
  updatedAt: string
  createdAt: string
  task: string
  group: number
}

export type TaskRecurrence = {
  id?: number
  startTime?: string
  endTime?: string
  intervalPattern?: string
  interval?: {}
  frequency?: {
    frequency?: string
  }
  fuzzyTime?: number
  rangePattern?: number
  range?: {}
  updatedAt?: string
  createdAt?: string
  task?: string
}

export type TaskUser = {
  id: string
  name: string
  avatar?: string
}

export type Task = {
  id: string
  user: TaskUser
  title: string
  level: TaskCategory
  status: TaskCategory
  priorityGroup?: TaskCategory
  mode: TaskMode
  activatedAt?: string
  completionAt?: string
  updatedAt: string
  createdAt: string
  dueAt: string
  taskDetails?: TaskDetails
  taskAssignees: Array<TaskAssignees>
  taskRecurrence?: Array<TaskRecurrence>
  group?: TaskGroup
  taskAttachmentsCount: number
  children: Array<any>
  scheduledTask: ScheduledTask
  pendingAssignees: PendingAssignee[]
  originalTaskId?: string
  parent?: UUIDTitle
  isCustomValueRequired?: boolean
  curriculumTaskInstance?: { id: number; status: string }
  assignedTaskRoles: string[]
  roles?: number[]
  manager: TaskUser
  delegationType: DelegationType
}

export type TaskGroupMetadata = {
  color: string
  icon: string
  details?: string
  pinned?: boolean
  checked?: boolean
}

type PendingMembers = {
  email: string
  status: string
  role: TaskRole[]
}

export type TaskGroup = {
  id: number
  title: string
  description: string
  metadata: TaskGroupMetadata
  isDefault?: boolean
  userOrder: number
  groupMembers?: GroupMember[]
  pendingMembers?: PendingMembers[]
  status?: number | string
  owner?: TaskUser
  alternativeTitle?: string
}

export type RoleMappingForTaskNode = Partial<
  Pick<RoleMappingBase, 'assigningRole' | 'email' | 'managingRole'>
> & {
  assignedEmail?: string
}

export type TaskNodeData = {
  id: string
  parent?: string
  originalParent?: string
  title: string
  originalTitle?: string
  level: number
  originalLevel?: number
  mode?: TaskMode
  originalMode?: TaskMode
  group?: TaskGroup
  taskDetails?: Partial<TaskDetails>
  originalTaskDetails?: Partial<TaskDetails>
  dueAt?: string | null
  originalDueAt?: string | null
  taskAssignees?: Partial<TaskAssignees>[] | []
  scheduledTask?: Partial<ScheduledTask> | null
  originalScheduledTask?: Partial<ScheduledTask> | null
  hasChildren?: boolean
  children?: TaskNodeData[]
  forceToShowIcons?: boolean
  isNew?: boolean
  priorityGroup?: number
  originalPriorityGroup?: number
  taskRole?: RoleMappingForTaskNode | null
  originalTaskRole?: RoleMappingForTaskNode | null
  pendingAssignees?: PendingAssignee[]
  roles?: number[]
  status?: TaskStatus
}

export type CommitChangesFunction = (changes: ChangeSet) => void

export type GroupMember = {
  id: number
  user: string
  email: string
  name: string
  role: string
  rolesData: TaskRole[]
  status: string
  group: number
  avatar?: string
}

export type Group = {
  id: number
  title: string
  groupMembers: GroupMember[]
}

export type MenuItemChild = {
  label?: React.ReactNode
  key?: React.Key | null | string
  disabled?: boolean
  status?: string
  user?: string
  email?: string
  group?: number | string
  name?: string
  image?: string
}

export type MenuItem = {
  label: string
  key: number | string
  children: MenuItemChild[]
}

export type InvitationTask = {
  id: string
  title: string
}

export type Invitation = {
  email: string
  task: InvitationTask
  status: string
  created_at: string
}

export type PaginationOptions = {
  limit?: number
  offset?: number
  page?: number
}

export type DescriptionJsonNode = {
  children?: DescriptionJsonNode[] | undefined
  text?: string
}

export type CurrentStatus = {
  event: string
  hasAccess: boolean
  limit: number
  package: string
  title: string
  currentCount: number
  status?: string
  unitType?: string
  currentStorage?: number
}

export type SubscriptionEvents = {
  ownedGroup: CurrentStatus
  ownedGroupTotal: CurrentStatus
  groupMembership: CurrentStatus
  ownedGroupMemberAmount: CurrentStatus
  delegatedTasks: CurrentStatus
  activeTasks: CurrentStatus
  totalTasks: CurrentStatus
  taskAttachments: CurrentStatus
  storage: CurrentStatus
  integrations: CurrentStatus
}

export type SubscriptionStatus = {
  licensingEvent: string
  licensingStatus: string
  licensingEntity?: string
}

export type Subscription = {
  package: string
  currentStatus: string
  trialEndAt: string
  lastPaymentAt: string
}

export type CompletionReportType = {
  name: string
  key: number
  value: number
}

export type CustomDataReportType = {
  id: string
  startDate: string
  customValue: CustomFieldValueDataFormat
}

export type TaskFormProps = {
  description: string
  group: number | undefined
  priorityGroup: number | undefined
  level: number | undefined
  scheduledTask: Partial<ScheduledTask> | undefined
  status: number | undefined
  taskAssignees: { email: string; role?: number }[] | undefined
  title: string
}

export type CustomFieldForwardRefType = {
  isNotDirty: () => boolean
  cancelChanges: () => void
  saveData: (taskId: string) => void
  isValidForm: () => Promise<boolean>
}

export type TaskAssignee = {
  label?: string | React.ReactNode
  value?: string
  key?: string
  user?: string
  email?: string
  group?: number | string
}

export type FormTaskType = {
  title: string
  taskAssignees: TaskAssignee[]
  description: string
  category: number
  group: number
  level: number
  priorityGroup: number
  status: number
  flag: number
  dueDate?: dayjs.Dayjs
  // routine?: RecurrenceArgs
  scheduling?: Object
  update_current_instance?: boolean
  update_routine_definition?: boolean
}

export type TaskEditFormForwardRefType = {
  submitFormAndComplete: (defaultValues?: Partial<FormTaskType>) => void
  checkIsFormDirty: () => boolean
}
