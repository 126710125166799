import React, { ReactNode } from 'react'
import { Popover } from 'antd'
import { Trans } from 'react-i18next'
import { Link } from 'react-router-dom'
import useSubscription from 'hooks/useSubscription'

type RestrictedComponentPopoverProps = {
  children: ReactNode
  messages: string[]
}

const RestrictedComponentPopover = ({
  children,
  messages,
}: RestrictedComponentPopoverProps) => {
  const { billingUrl } = useSubscription((state) => state)
  const fallbackUrl = 'https://tepintasks.com/my-account/'

  const content = (
    <div style={{ width: 300 }}>
      {messages.map((message, index) => (
        <p key={index}>{message}</p>
      ))}
      <p>
        <Trans
          i18nKey="subscription.click-here"
          components={{
            here: <Link to={billingUrl || fallbackUrl} target="_blank" />,
          }}
        />
      </p>
    </div>
  )

  return (
    <Popover content={content} trigger="hover">
      {children}
    </Popover>
  )
}

export default RestrictedComponentPopover
