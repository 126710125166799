import { BookOutlined, DeleteOutlined, PlusOutlined } from '@ant-design/icons'
import LensIcon from '@mui/icons-material/Lens'
import { SvgIcon, Switch } from '@mui/material'
import type { InputRef } from 'antd'
import {
  App,
  Button,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd'
import { isAxiosError } from 'axios'
import { iconsTaskGroup } from 'components/CheckboxIconTag/CheckboxIconTag'
import useStore from 'hooks/useStore'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Group, TaskGroup } from 'types/Tasks'
import {
  GroupActions,
  GroupAssociated,
  GroupFields,
  PermissionRoleIDs,
  TaskInternals,
  getAssignedRolesPerGroup,
  hasPermission,
} from 'utils/permissions'
import { isFavoriteGroup, isGroupOwner } from 'utils/taskUtils'

const { TextArea } = Input
const { Option } = Select
const { Text, Title } = Typography

export const colorList = [
  '#7EC242',
  '#47BC7D',
  '#C2DD90',
  '#FFB9E7',
  '#FF3DA5',
  '#FF4D4F',
  '#FF8515',
  '#FFD04D',
  '#15BED1',
  '#1A7AFF',
  '#9747FF',
  '#513AE1',
]

export const iconList = Object.keys(iconsTaskGroup).map((iconName: string) => ({
  value: iconName,
  label: iconsTaskGroup[iconName],
}))

type GroupFormProps = {
  handleClose: () => void
  initialValues?: TaskGroup
  handleDelete?: () => Promise<void>
}

const GroupForm = ({
  handleClose,
  initialValues,
  handleDelete,
}: GroupFormProps) => {
  const app = App.useApp()
  const { t } = useTranslation()
  const {
    createGroup,
    groupRolePermissions,
    updateGroup,
    archiveMemberGroup,
    inviteGroupMember,
    user,
    favoriteGroup,
    updateUserConfig,
  } = useStore((state) => state)
  const [showWarning, setShowWarning] = useState(false)
  const [isOwner, setIsOwner] = useState(false)
  const [isFavorite, setIsFavorite] = useState(false)
  const [title, setTitle] = useState(initialValues?.title || '')
  const [description, setDescription] = useState(
    initialValues?.description || '',
  )
  const [selectedColor, setSelectedColor] = useState(
    initialValues?.metadata.color || colorList[0],
  )
  const [selectedIcon, setSelectedIcon] = useState(
    initialValues?.metadata.icon || iconList[0].value,
  )
  const [draftMembers, setDraftMembers] = useState<
    { email: string; role: number }[]
  >([])
  const [isValidatingAddMember, setIsValidatingAddMember] =
    useState<boolean>(false)
  const [inputVisible, setInputVisible] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const inputRef = useRef<InputRef>(null)
  const tagRef = useRef<HTMLElement>(null)
  const [selectedRole, setSelectedRole] = useState<number | undefined>(
    undefined,
  )

  const { groups } = useStore((state) => state.tasks)
  const { subscriptionEvents } = useStore((state) => state)
  const { roleList } = useStore((state) => state)
  const { ownedGroupMemberAmount } = subscriptionEvents
  const isDefaultGroup = initialValues?.isDefault

  useEffect(() => {
    if (inputVisible) {
      inputRef.current?.focus()
    }
  }, [inputVisible])

  useEffect(() => {
    const isOwner = isGroupOwner(initialValues, user?.data?.id)
    setIsOwner(isOwner)
    setIsFavorite(
      isFavoriteGroup(isDefaultGroup, initialValues?.id, favoriteGroup),
    )
  }, [setIsOwner, initialValues, user?.data?.id, favoriteGroup, isDefaultGroup])

  const isArchived = useMemo(
    () =>
      initialValues?.groupMembers?.find((gm) => gm.email === user?.data.email)
        ?.status === 'inactive',
    [initialValues, user?.data.email],
  )

  const isOwnerArchived = useMemo(
    () => initialValues?.status === 'archived',
    [initialValues],
  )

  const assignedRoles = useMemo(
    () => getAssignedRolesPerGroup(initialValues, user?.data?.id),
    [initialValues, user?.data?.id],
  )

  const hasDeletePermission = useMemo(
    () =>
      hasPermission(
        GroupActions.DELETE_GROUP,
        assignedRoles,
        groupRolePermissions,
      ),
    [assignedRoles, groupRolePermissions],
  )

  const hasPermissionToAddAdminMembers = useMemo(
    () =>
      hasPermission(
        GroupAssociated.MANAGE_ADMINS,
        assignedRoles,
        groupRolePermissions,
      ),
    [assignedRoles, groupRolePermissions],
  )

  const roleOptions = useMemo(
    () =>
      roleList
        .filter((role) =>
          role.id === PermissionRoleIDs.SUPERADMIN ||
          role.id === PermissionRoleIDs.ADMIN
            ? !(!!initialValues && !hasPermissionToAddAdminMembers)
            : true,
        )
        .map((role) => ({
          label: role.title,
          value: role.id,
        })),
    [hasPermissionToAddAdminMembers, initialValues, roleList],
  )

  const submitIsFavorite = async (groupId: number) => {
    const previousIsFavorite = groupId === favoriteGroup
    if (previousIsFavorite === isFavorite) {
      return
    }
    const newFavorite = isFavorite ? groupId : null
    try {
      await updateUserConfig({ group: newFavorite })
    } catch (error) {
      if (isAxiosError(error)) {
        const message = error.response?.data?.fallback_message || error.message
        app.notification.error({
          message,
        })
      }
    }
  }

  const submitForm = async () => {
    if (inputValue) {
      app.notification.warning({
        message: t('settings.group-member-add-discard', {
          ns: 'validation',
        }),
      })
      return
    }
    if (
      isDuplicatedGroupName(title, groups, initialValues?.id, user?.data?.id)
    ) {
      app.notification.warning({
        message: t('settings.group-name-conflict', {
          ns: 'validation',
        }),
      })
      return
    }
    let group: Group
    if (initialValues) {
      try {
        group = await updateGroup({
          id: initialValues.id,
          metadata: {
            color: selectedColor,
            icon: selectedIcon,
          },
          title,
          description: description,
          userOrder: initialValues.userOrder,
        })
        await submitIsFavorite(initialValues.id)
      } catch (error) {
        if (isAxiosError(error)) {
          const message =
            error.response?.data?.fallback_message || error.message
          app.notification.error({
            message,
          })
        }
        return
      }
    } else {
      try {
        group = await createGroup({
          title,
          description: description,
          metadata: {
            color: selectedColor,
            icon: selectedIcon,
          },
        } as TaskGroup)
        await submitIsFavorite(group.id)
      } catch (error) {
        if (isAxiosError(error)) {
          const message =
            error.response?.data?.fallback_message || error.message
          app.notification.error({
            message,
          })
        }
        return
      }
    }
    try {
      await Promise.all(
        draftMembers.map(({ email, role }) => {
          return inviteGroupMember(group.id, email.toLowerCase(), role)
        }),
      )
      setInputVisible(false)
      handleClose && handleClose()
    } catch (error) {
      if (isAxiosError(error)) {
        const message = error.response?.data?.fallback_message || error.message
        app.notification.error({
          message,
        })
      }
    }
  }

  const handleEmailClose = (removedEmail: string) => {
    const newDraftMembers = draftMembers.filter(
      (member) => member.email !== removedEmail,
    )
    setDraftMembers(newDraftMembers)
  }

  const showInput = () => {
    setInputVisible(true)
  }

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value)
  }

  const handleAddMember = () => {
    setIsValidatingAddMember(true)
    if (!inputValue || !selectedRole) {
      return
    }
    if (draftMembers.find((member) => member.email === inputValue)) {
      const newDraftMembers = draftMembers.map((member) =>
        member.email === inputValue
          ? { ...member, role: selectedRole }
          : member,
      )
      setDraftMembers(newDraftMembers)
    } else {
      setDraftMembers([
        ...draftMembers,
        { email: inputValue, role: selectedRole },
      ])
    }
    setInputValue('')
    setSelectedRole(undefined)
    setIsValidatingAddMember(false)
  }

  const handleEditMember = (emailToEdit: string, role: number) => {
    const newDraftMembers = draftMembers.filter(
      (member) => member.email !== emailToEdit,
    )
    setDraftMembers(newDraftMembers)
    setInputValue(emailToEdit)
    setSelectedRole(role)
  }

  const groupDelete = async () => {
    handleDelete && (await handleDelete())
    handleClose()
  }

  const groupArchive = async () => {
    try {
      const newStatus = isArchived || isOwnerArchived ? 1 : 2
      const hasPermissionToUpdateStatus = hasPermission(
        GroupFields.STATUS,
        assignedRoles,
        groupRolePermissions,
      )
      hasPermissionToUpdateStatus
        ? await updateGroup({
            id: initialValues?.id,
            status: newStatus,
          })
        : await archiveMemberGroup(
            initialValues?.id as number,
            initialValues?.groupMembers?.find(
              (gm) => gm.email === user?.data.email,
            )?.id as number,
            newStatus,
          )
    } catch (error) {
      if (isAxiosError(error)) {
        const message = error.response?.data?.fallback_message || error.message
        app.notification.error({
          message,
        })
      }
    }
    handleClose()
  }

  const emailInputStyle: React.CSSProperties = {
    width: 222,
    height: 32,
    marginInlineEnd: 8,
    verticalAlign: 'top',
    textTransform: 'lowercase',
  }

  const emailPlusStyle: React.CSSProperties = {
    borderStyle: 'dashed',
    paddingBlock: 6,
  }

  const selectRoleInputStyle: React.CSSProperties = {
    width: 222,
    height: 32,
    marginInlineEnd: 8,
    verticalAlign: 'top',
  }

  return (
    <>
      {handleDelete && !isDefaultGroup && (
        <Row justify="end">
          <Button
            onClick={groupArchive}
            icon={<BookOutlined />}
            disabled={!isOwner && isOwnerArchived}
            style={{ marginRight: '8px' }}
          >
            {isArchived || (isOwner && isOwnerArchived)
              ? t('group.unarchive')
              : t('group.archive')}
          </Button>
          {hasDeletePermission && (
            <Button
              danger
              onClick={() => setShowWarning(true)}
              icon={<DeleteOutlined />}
            >
              {t('group.delete')}
            </Button>
          )}
          <Modal
            open={showWarning}
            onCancel={() => setShowWarning(false)}
            footer={(_, { CancelBtn }) => (
              <>
                <CancelBtn />
                <Button danger onClick={groupDelete} icon={<DeleteOutlined />}>
                  {t('group.delete')}
                </Button>
              </>
            )}
          >
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-around',
                alignItems: 'center',
              }}
            >
              <Typography.Text>{t('group.delete-warning')}</Typography.Text>
            </div>
          </Modal>
        </Row>
      )}
      <Form
        layout="vertical"
        initialValues={{
          name: title,
          description: description,
          color: selectedColor,
          icon: selectedIcon,
        }}
        style={{ marginBottom: 40 }}
      >
        <Row>
          <Form.Item
            required
            name="name"
            label={t('group.name')}
            style={{ flex: 1 }}
          >
            <Input
              onChange={(e) => setTitle(e.target.value)}
              placeholder={t('group.add-name') as string}
              disabled={
                (!!initialValues &&
                  !hasPermission(
                    GroupFields.TITLE,
                    assignedRoles,
                    groupRolePermissions,
                  )) ||
                isArchived ||
                isOwnerArchived
              }
            />
          </Form.Item>
        </Row>
        <Row>
          <Form.Item
            name="description"
            label={'Description'}
            style={{ flex: 1 }}
          >
            <TextArea
              rows={4}
              maxLength={250}
              onChange={(e) => setDescription(e.target.value)}
              placeholder="Enter a brief description of the group (e.g., 'This is a team of software developers focused on creating innovative applications.')"
              disabled={
                (!!initialValues &&
                  !hasPermission(
                    GroupFields.TITLE,
                    assignedRoles,
                    groupRolePermissions,
                  )) ||
                isArchived ||
                isOwnerArchived
              }
            />
          </Form.Item>
        </Row>
        <Row>
          <Col span={11} style={{ marginRight: '39px' }}>
            <Form.Item
              required
              name="color"
              label={t('group.color')}
              style={{ flex: 1 }}
            >
              <Select
                onChange={setSelectedColor}
                disabled={
                  (!!initialValues &&
                    !hasPermission(
                      GroupFields.METADATA,
                      assignedRoles,
                      groupRolePermissions,
                    )) ||
                  isArchived ||
                  isOwnerArchived
                }
              >
                {colorList.map((color) => (
                  <Option key={color} value={color} label={color}>
                    <Row align="middle">
                      <div
                        style={{
                          backgroundColor: color,
                          height: '20px',
                          width: '20px',
                          borderRadius: '4px',
                          marginRight: '8px',
                        }}
                      />
                      <span>{t(`group.${color}`)}</span>
                    </Row>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={4}>
            <Form.Item
              required
              name="icon"
              label={t('group.icon')}
              style={{ flex: 1 }}
            >
              <Select
                onChange={setSelectedIcon}
                disabled={
                  (!!initialValues &&
                    !hasPermission(
                      GroupFields.METADATA,
                      assignedRoles,
                      groupRolePermissions,
                    )) ||
                  isArchived ||
                  isOwnerArchived
                }
              >
                {iconList.map((icon) => (
                  <Option
                    key={icon.value}
                    value={icon.value}
                    label={icon.value}
                  >
                    <Row align="middle" gutter={2} style={{ height: '100%' }}>
                      <SvgIcon
                        component={icon.label}
                        style={{ marginRight: '8px' }}
                      />
                    </Row>
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item style={{ flex: 1 }}>
              <Switch
                checked={isFavorite}
                onChange={() => setIsFavorite((prevState) => !prevState)}
                disabled={initialValues && (!isOwner || isOwnerArchived)}
              />
              {t('group.is-default')}
            </Form.Item>
          </Col>
        </Row>
        {!isDefaultGroup &&
          (!initialValues ||
            hasPermission(
              GroupAssociated.MANAGE_USER,
              assignedRoles,
              groupRolePermissions,
            )) &&
          !isArchived &&
          !isOwnerArchived && (
            <Row>
              <Form.Item
                required
                label={t('group.members')}
                style={{ flex: 1 }}
              >
                {inputVisible && (
                  <Row>
                    <Col span={24} style={{ marginBottom: 8 }}>
                      <Space size={[0, 8]} wrap>
                        <Input
                          ref={inputRef}
                          type="text"
                          size="small"
                          style={emailInputStyle}
                          value={inputValue}
                          onChange={handleInputChange}
                          placeholder="johnsmith@email.com"
                          status={
                            isValidatingAddMember && !inputValue
                              ? 'error'
                              : undefined
                          }
                        />
                        <Select
                          allowClear
                          placeholder={t('group.select-role')}
                          options={roleOptions}
                          style={selectRoleInputStyle}
                          onChange={(value) => setSelectedRole(value)}
                          value={selectedRole}
                          status={
                            isValidatingAddMember && !selectedRole
                              ? 'error'
                              : undefined
                          }
                        />
                        <Button
                          icon={<PlusOutlined />}
                          onClick={handleAddMember}
                        >
                          {t('group.add-email')}
                        </Button>
                      </Space>
                    </Col>
                  </Row>
                )}
                <Row>
                  <Col span={24}>
                    <Space size={[0, 8]} wrap>
                      <Space size={[0, 8]} wrap>
                        {draftMembers.map(({ email, role }, index) => {
                          const roleDescription = roleList.find(
                            (rl) => rl.id === role,
                          )?.title
                          const isLongEmail = email.length > 20
                          const emailElem = (
                            <Tag
                              key={email}
                              closable={true}
                              style={{ userSelect: 'none' }}
                              onClose={() => handleEmailClose(email)}
                            >
                              <Text
                                onDoubleClick={() =>
                                  handleEditMember(email, role)
                                }
                              >
                                {isLongEmail ? `${email.slice(0, 20)}…` : email}
                                {' (' + roleDescription + ')'}
                              </Text>
                            </Tag>
                          )
                          return isLongEmail ? (
                            <Tooltip title={email} key={email}>
                              {emailElem}
                            </Tooltip>
                          ) : (
                            emailElem
                          )
                        })}
                        {!inputVisible && (
                          <Tag
                            ref={tagRef}
                            style={emailPlusStyle}
                            onClick={showInput}
                            tabIndex={0}
                          >
                            <PlusOutlined /> {t('group.add-email')}
                          </Tag>
                        )}
                      </Space>
                    </Space>
                  </Col>
                </Row>
              </Form.Item>
            </Row>
          )}

        <Row>
          {ownedGroupMemberAmount.title ? (
            <Col flex="2">
              <Space style={{ width: '100%' }}>
                <Title style={{ marginBottom: '0' }} level={5}>
                  {ownedGroupMemberAmount.title}:
                </Title>
                <Text>
                  {`${ownedGroupMemberAmount.currentCount} of ${ownedGroupMemberAmount.limit}`}
                </Text>
                <LensIcon
                  style={{
                    color: `var(--licensing-${ownedGroupMemberAmount.status}-color)`,
                    marginTop: 8,
                  }}
                />
              </Space>
            </Col>
          ) : null}
          <Col
            flex="auto"
            style={{ justifyContent: 'flex-end', display: 'inline-flex' }}
          >
            {handleClose && (
              <Button onClick={handleClose} disabled={false}>
                {t('group.close')}
              </Button>
            )}
            <Button
              type="primary"
              style={{
                marginLeft: '16px',
              }}
              htmlType="submit"
              onClick={submitForm}
              disabled={
                (!!initialValues &&
                  !hasPermission(
                    TaskInternals.SUBMIT_GROUP,
                    assignedRoles,
                    groupRolePermissions,
                  )) ||
                isArchived ||
                isOwnerArchived
              }
            >
              {t('group.save')}
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  )
}

const isDuplicatedGroupName = (
  groupName: string,
  groups?: TaskGroup[],
  groupId?: number,
  userId?: string,
) => {
  const personalGroups = groups?.filter(
    (group) => group.id !== groupId && isGroupOwner(group, userId),
  )
  return !!personalGroups?.find(
    (group) =>
      group.title.toLowerCase().trim() === groupName.toLowerCase().trim(),
  )
}

export default GroupForm
