import Routes from './Routes'
import Providers from './AppProvider'
import VersionBanner from './components/VersionBanner'
// eslint-disable-next-line no-console
console.log("ANTONIO - THIS WORKS")
const App = () => {
  return (
    <Providers>
      <VersionBanner />
      <Routes />
    </Providers>
  )
}

export default App
