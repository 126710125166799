import dayjs from 'dayjs'
import useStore from 'hooks/useStore'
import { CollapseProps } from 'rc-collapse'
import { useTranslation } from 'react-i18next'
import { TaskLists } from 'services/Tasks.slice'
import {
  TaskItem,
  TaskLevel,
  TaskPriorityGroupGlobal,
  TaskStatusId,
} from 'types/Tasks'
import { getFilteredPriorityGroupBySelectables } from 'utils/taskUtils'
import CustomPanelHeader from '../CustomPanelHeader'
import CustomPanel from './CustomPanel'
import styles from './TasksList.module.scss'
import { Dispatch, SetStateAction } from 'react'

const getNumberOfDueTodayTasks = (tasks: TaskItem[]) => {
  return tasks.filter((task) => {
    if (!task.dueAt) {
      return false
    }
    try {
      const dueDate = dayjs(task.dueAt)
      return dayjs().isSame(dueDate, 'day')
    } catch (e) {
      console.error(e)
      return false
    }
  }).length
}

const getNumberOfCompletedTasks = (tasks: TaskItem[]) => {
  if (!tasks) {
    return 0
  }
  return tasks.filter((task) => task?.status?.id === TaskStatusId.DONE).length
}

export const useTasksList = (tasks: TaskLists) => {
  const { t } = useTranslation()

  const priorityGroups = getFilteredPriorityGroupBySelectables(
    useStore((state) => state.priorityGroups),
  )
  const BASE_TASK_ID = 9
  const todayTaskPriorityGroupId = 0
  const allTasks = tasks && Object.values(tasks).flat()
  const dueTodayTasks = getNumberOfDueTodayTasks(allTasks)
  const completedTasks = getNumberOfCompletedTasks(
    tasks[todayTaskPriorityGroupId || 0],
  )
  const totalTodayTasks = tasks[todayTaskPriorityGroupId]?.length || 0

  const baseTasksFilter: TaskLevel = {
    id: BASE_TASK_ID,
    title: t('my-today-page.base-tasks'),
  }
  const selectedLevels = useStore((state) => state.selectedLevels)
  const taskLevels = useStore((state) => state.taskLevels)

  let filterText = t('my-today-page.viewing-levels', {
    ns: 'common',
  })

  if (selectedLevels.length > 0) {
    const sortedselectedLevels = [...selectedLevels].sort((a, b) => a - b)
    const maxLevel = sortedselectedLevels[0]
    const minLevel = sortedselectedLevels[sortedselectedLevels.length - 1]

    const fromLevel =
      maxLevel === BASE_TASK_ID
        ? baseTasksFilter.title
        : taskLevels.find((level) => level.id === maxLevel)?.title
    const toLevel =
      maxLevel === BASE_TASK_ID
        ? baseTasksFilter.title
        : taskLevels.find((level) => level.id === minLevel)?.title
    const fromToText = t('my-today-page.from-to', {
      ns: 'common',
      x: fromLevel,
      y: toLevel,
    })

    filterText =
      t('my-today-page.viewing-levels', {
        ns: 'common',
      }).toString() + (selectedLevels.length > 0 ? ' ' + fromToText : '')
  }

  const getItems = (
    tasks: TaskLists,
    activeKeys: string[],
    isExpanded: boolean,
    taskPriorityGroups?: TaskPriorityGroupGlobal[],
    individualToggledTaskIDs?: string[],
    setIndividualToggledTaskIDs?: Dispatch<SetStateAction<string[]>>,
  ) => {
    const items: CollapseProps['items'] = []
    if (!taskPriorityGroups) {
      return items
    }

    taskPriorityGroups.forEach((priorityGroup) => {
      const key = priorityGroup.id.toString()
      const value = tasks[key] || []
      const title =
        priorityGroup.id === todayTaskPriorityGroupId
          ? 'Today'
          : priorityGroup.title
      if (value.length === 0) {
        return
      }
      items.push({
        key: key,
        label: (
          <CustomPanelHeader
            title={`${title} (${value.length})`}
            isActive={activeKeys.includes(key)}
            data-testid="mytoday-panel-header"
          />
        ),
        showArrow: false,
        className: styles.customPanel,
        children: (
          <CustomPanel
            id={key}
            tasks={value}
            isExpanded={isExpanded}
            individualToggledTaskIDs={individualToggledTaskIDs}
            setIndividualToggledTaskIDs={setIndividualToggledTaskIDs}
          />
        ),
      })
    })
    return items
  }

  return {
    allTasks,
    baseTasksFilter,
    completedTasks,
    dueTodayTasks,
    filterText,
    getItems,
    priorityGroups,
    todayTaskPriorityGroupId,
    totalTodayTasks,
  }
}
