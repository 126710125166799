import type { StateCreator } from 'zustand'
import { produce } from 'immer'
import { ApiState } from './Auth.slice'

enum TemplateOptions {
  'due.today' = 'due.today',
  delegated = 'delegated',
}
type DelegatedVariables = {
  taskTitle: string
  taskId: string
}
type BodyVariables = DelegatedVariables
enum NotificationStatus {
  read = 'read',
  unread = 'unread',
}

interface PushSubscription {
  endpoint: string
  keys: {
    p256dh: string
    auth: string
  }
}

export type Notification = {
  id: string
  templateName: TemplateOptions
  variables?: BodyVariables
  actions?: { [key: string]: string }
  createdAt: string
  receivedAt: string
  status: NotificationStatus
  readAt: string | null
  taskId: string
}

export type EventLog = {
  id: number
  action: string
  contentType: number
  objectId: string
  timestamp: string
  user: {
    fullName: string
  }
}

export interface NotificationState extends ApiState {
  notifications: Notification[]
  hasUnreadNotifications: boolean
  getNotifications: () => Promise<void>
  getTaskNotifications: (taskID: string) => Promise<EventLog[]>
  setHasUnread: (hasUnread: boolean) => void
  setNotifications: (notifications: Notification[]) => void
  addNotification: (notification: Notification) => void
  updateNotificationStatus: (index: number, status: NotificationStatus) => void
  patchReadAt: (notificationIds: string[]) => Promise<void>
  postSubscription: (subscription: PushSubscription) => Promise<void>
  deleteSubscription: () => Promise<void>
}

const removePaginationQuery = '?page=1&offset=0&limit=100'

export const createNotificationSlice: StateCreator<
  NotificationState,
  [['zustand/immer', never]]
> = (set, get) => ({
  notifications: [],
  hasUnreadNotifications: false,
  getNotifications: async () => {
    const response = await get().api!(
      'GET',
      `/notifications/${removePaginationQuery}`,
    )

    set(
      produce((draft) => {
        draft.notifications = response.data.results
      }),
    )
    set(
      produce((draft) => {
        draft.hasUnreadNotifications = response.data.results.some(
          (n: Notification) => n.readAt === null,
        )
      }),
    )
    const unreceivedIds = response.data.results
      .filter((n: Notification) => n.receivedAt === null)
      .map((n: Notification) => n.id)
    if (unreceivedIds.length > 0) {
      const response = await get().api!('PATCH', '/notifications/received', {
        ids: unreceivedIds,
      })
      if (response.status === 200) {
        unreceivedIds.forEach((id: string) => {
          set(
            produce((draft) => {
              const notificationIndex = draft.notifications.findIndex(
                (el: Notification) => el.id === id,
              )
              draft.notifications[notificationIndex].receivedAt = new Date()
            }),
          )
        })
      }
    }
  },
  getTaskNotifications: async (taskID: string) => {
    const response = await get().api!('GET', `/notifications/events/${taskID}`)
    return response.data
  },
  patchReadAt: async (notificationIds: string[]) => {
    const response = await get().api!('PATCH', '/notifications/read', {
      ids: notificationIds,
    })
    if (response.status === 200) {
      notificationIds.forEach((id: string) => {
        set(
          produce((draft) => {
            const notificationIndex = draft.notifications.findIndex(
              (el: Notification) => el.id === id,
            )
            draft.notifications[notificationIndex].readAt = new Date()
          }),
        )
      })
      set(
        produce((draft) => {
          draft.hasUnreadNotifications = draft.notifications.some(
            (n: Notification) => n.readAt === null,
          )
        }),
      )
    }
  },
  setHasUnread: (hasUnread: boolean) => {
    set((draft) => {
      draft.hasUnreadNotifications = hasUnread
    })
  },
  setNotifications: (notifications: Notification[]) => {
    set((draft) => {
      draft.notifications = notifications
    })
  },
  addNotification: (notification: Notification) => {
    set((draft) => {
      draft.notifications.push(notification)
    })
  },
  updateNotificationStatus: (
    index: number,
    notificationStatus: NotificationStatus,
  ) => {
    set((draft) => {
      draft.notifications[index].status = notificationStatus
    })
  },
  postSubscription: async (subscription: PushSubscription) => {
    await get().api!('POST', '/notifications/subscription', subscription)
  },
  deleteSubscription: async () => {
    try {
      await get().api!('DELETE', '/notifications/subscription', {
        deviceType: 'web',
      })
    } catch (error) {
      console.error('Error deleting subscription:', error)
    }
  },
})
