import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Alert, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { getUpdateServiceWorker } from '../../serviceWorkerRegistration';
import useStore from 'hooks/useStore';

const VersionBanner: React.FC = () => {
  const [updateServiceWorker, setUpdateServiceWorker] = useState<(() => Promise<void | boolean>) | null>(getUpdateServiceWorker());
  const { t } = useTranslation()
  const location = useLocation()
  const logout = useStore((state) => state.logout)

  useEffect(() => {
    const updateFunction = getUpdateServiceWorker()
    if (!!updateFunction) {
      setUpdateServiceWorker(() => updateFunction)
    }
  }, [location, updateServiceWorker])

  const handleCloseApp = () => {
    if (updateServiceWorker !== null) {
      updateServiceWorker().then(() => {
        logout()
      }).catch((error) => {
        console.error('Error updating service worker:', error);
      });
    }
  };

  return (
    <>
      {updateServiceWorker && (
        <Alert
          severity="info"
          action={
            <Button color="inherit" onClick={handleCloseApp}>
              {t('new-version.close-app')}
            </Button>
          }
        >
          {t('new-version.description')}
        </Alert>
      )}
    </>
  );
};

export default VersionBanner;
